import { useMutation } from "@tanstack/react-query";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useState } from "react";

import Button from "@/components/buttons/Button";
import Seo from "@/components/Seo";

import withAuth from "@/HOCs/withAuth";
import { ProfileContext, ProfileContextType } from "@/providers/profile";
import { signInService } from "@/services";

const Index = () => {

  const { setProfile } = useContext<ProfileContextType>(ProfileContext);
  const router = useRouter();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false)
  const [inputValues, setInputValue] = useState({
    email: "",
    password: "",
  });
  const [validation, setValidation] = useState({
    email: "",
    password: "",
    customError: null
  });

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const signInMutation = useMutation(
    signInService.signIn,
    {
      onSuccess: ({ data: { id } }) => {
        setProfile({ email, id });
        router.push('/dashboard');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        setValidation({
          ...validation,
          customError: error.response.data.message,
        });
      }
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
  }

  const checkValidation = () => {
    const errors = validation;

    if (!validateEmail(inputValues.email)) {
      errors.email = "Ingresa una dirección email válida";
    } else {
      errors.email = "";
    }

    const password = inputValues.password;
    if (password.length < 6) {
      errors.password = "La contraseña debe de ser de más de 6 caracteres";
    } else {
      errors.password = "";
    }
    setValidation({
      ...errors
    });
  };

  const handleSubmit = (event: React.ChangeEvent<unknown>) => {
    event.preventDefault();
    checkValidation();
    if (validation.email === "" && validation.password === "") {
      signInMutation.mutate({
        email,
        password,
      });
    }
  };
  const toggle = () => {
    setOpen(!open)
  }

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setEmail(event.target.value);
    handleChange(event);
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setPassword(event.target.value);
    handleChange(event);
  }

  return (
    <>
      <Seo
        templateTitle='Iniciar sesión'
        description='Inicia sesión en zumma.'
      />
      <div className='flex min-h-screen flex-col justify-center mx-7'>
        <div className="flex h-20">
          <div className="w-auto mt-6 self-end">
            <h1 className="font-primary" style={{ fontSize: "40px", lineHeight: "49px" }}>
              ¡Bienvenida otra vez!
            </h1>
          </div>
          <div className="w-1/2">
            <img src="/images/pose_5.png" alt="pose_5" className="fixed right-0 h-auto top-0" />
          </div>
        </div>
        <div>
          <form id='loginForm'
            onSubmit={handleSubmit}>
            <div className="font-normal text-lg w-9/12 h-11 mb-8 mt-6 inset-x-6 w-72">
              <p>Inicia sesión y sigue sumando a tu futuro</p>
            </div>
            <div className="login-form">
              <input
                className="peer border border-slate-400 login-input pb-0"
                type="email"
                name="email"
                id="email"
                required
                placeholder="null"
                onChange={handleEmail}
                value={inputValues.email}
              />
              <label htmlFor="correo" className="form-label">Correo electrónico</label>
            </div>
            <div>
              {validation.email && <p className=" text-red-700 text-xs">{validation.email}</p>}
            </div>
            <div className="login-form">
              <input
                className="login-input pb-0"
                type={!open ? 'password' : 'text'}
                name="password"
                id="password"
                placeholder="null"
                onChange={handlePassword}
                value={inputValues.password}
                required
              />
              <label htmlFor="contraseña" className="form-label">Contraseña</label>
              <div className="text-sm text-gray-600 block opacity-100" style={{
                transform: "translateY(-4em)", marginLeft: "85%"
              }}>
                {
                  !open
                    ? <img src="/icons/eyeIcon.svg" alt="eye" height={25} width={25} onClick={toggle} />
                    : <img src="/icons/eyeClosed.svg" alt="eyeClosed" height={25} width={25} onClick={toggle} />
                }
              </div>
            </div>
            <div>
              {validation.password && <p className=" text-red-700 text-xs">{validation.password}</p>}
            </div>
            <div className="h-4 not-italic font-medium text-xs leading-4 text-center left-60 underline decoration-1 text-right mt-4">
              <Link href="/recovery-password">
                <a>¿Olvidaste tu contraseña?</a>
              </Link>
            </div>
            <div className="login-form text-center">
              {validation.customError && <p className=" text-red-700 text-xs">{validation.customError}</p>}
              <Button
                className="mt-3 h-12 rounded-lg text-center"
                style={{ width: "327px" }}
                type='submit'
                id='submit-button'
                disabled={!email || !password}
                isLoading={signInMutation.isLoading}
              >
                Iniciar sesión
              </Button>
            </div>
            <div className="h-4 text-center	mt-12">
              <label>¿Aun no eres parte de Zumma?</label>
              <Link href="/signup">
                <a className="font-bold text-sm leading-4 text-primary-300 px-2">Registráte</a>
              </Link>
            </div>
          </form>
        </div >
      </div >
    </>
  );
}

export default withAuth(Index);
